import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // blogContent
    addBlogContent(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/blog-content', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateBlogContent(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/blog-content/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteBlogContent(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/blog-content/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBlogContents(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/blog-content', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBlogContentsForShowing(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/blog-content-showing', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSingleBlogContentForShowing(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/blog-content-showing/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSingleBlogContent(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/blog-content/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
